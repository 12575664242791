<template>
	<section class="permission">
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;">
			<el-table-column type="selection">
			</el-table-column>
			<el-table-column prop="username" label="用户名" width="150">
			</el-table-column>
			<el-table-column prop="name" label="店名" width="150">
			</el-table-column>
			<el-table-column prop="my_host" label="域名">
			</el-table-column>
			<el-table-column label="身份证">
				<template slot-scope="scope">
					<template>
						<img @click="seeImg(scope.row.identity_pic_front)" class="img" :src="scope.row.identity_pic_front" />
						<img @click="seeImg(scope.row.identity_pic_contrary)" class="img" :src="scope.row.identity_pic_contrary" />
					</template>
				</template>
			</el-table-column>
			<el-table-column prop="store_status" label="域名状态" width="100">
			</el-table-column>
			<el-table-column prop="contact" label="姓名" width="150">
			</el-table-column>
			<el-table-column prop="identity_num" label="身份证号" width="150">
			</el-table-column>
			<el-table-column label="操作" width="150" align="center">
				<template slot-scope="scope">
					<template>
						<el-button type="primary" plain size="small" @click="submit(scope.row)">去解析</el-button>
					</template>
				</template>
			</el-table-column>
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<el-button type="success" @click="submit(sels)">一键解析</el-button>
			<!--分页-->
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
			 :page-sizes="pagesizes" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</el-col>
		<el-dialog title="身份证照片" :visible.sync="showBigimg" center>
			<div class="text-align">
				<img class="imgBig" :src="imgurl" />
			</div>
		</el-dialog>
	</section>
</template>

<script>
	import {
		getThoststatus,
		hostSend
	} from '../../api/api';
	import {
		parse
	} from 'path';
	import {
		type
	} from 'os';
	export default {
		data() {
			return {
				filters: {
					keyword: ''
				},
				imgurl: '',
				showBigimg: false,
				listData: [],
				//分页
				total: 0,
				page: 1,
				pagesize: 10, //每页条数
				pagesizes: [10, 20, 50, 100], //可选条数
				listLoading: false,
				sels: [], //列表选中列
			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			getList(val) {
				if (val == '0') this.page = 1
				let para = {
					page: this.page,
					host_status: 1, //0-未注册，1-已注册，2-等待解析，3-开通成功
					pagesize: this.pagesize //分页条数
				};
				this.listLoading = true;
				getThoststatus(para).then((res) => {
					if (res.code == 1) {
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//勾选
			selsChange: function(sels) {
				this.sels = sels;
				// console.log(this.sels)
			},
			submit(data) {
				let para = {
					stores: []
				};
				// 判断是批量还是单选 Object or Array
				data.constructor === Object ? para.stores.push(data) : para.stores = data;
				hostSend(para).then((res) => {
					if (res.code == 1) {
						this.getList();
					} else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			seeImg(url) {
				this.imgurl = url
				this.showBigimg = true
			}
		},
		mounted() {
			//获取列表数据
			this.getList();
		}
	}
</script>

<style lang="scss" scoped>
	@import "../../styles/permission.scss";

	.img {
		width: 80px;
		height: 50px;
		padding: 0;
	}

	.imgBig {
		width: 800px;
		height: 500px;
		margin: 0 auto;
	}

	.text-align {
		text-align: center;
	}
</style>
